<script setup>
  const client = useStrapiClient()

  const { notification } = defineProps({
    notification: { type: Object, required: true }
  })

  const { comment, application, job } = notification

  const title = {
    comment: 'Nouveau commentaire',
    commentMention: 'Nouvelle mention',
    jobCreated: "Votre offre vient d'être publiée",
    endDateOver: 'Votre offre est clôturée',
    endDateOverUrgent: 'Une de vos offres est clôturée avec des candidatures non traitées',
    endDateClose: 'Votre offre se clôture bientôt',
    application: 'Nouvelle candidature',
    spontaneousApplication: 'Nouvelle candidature spontanée',
    recruiterAdded: 'Vous avez été ajouté à une offre',
    recruitment: 'Un nouveau collaborateur vous a rejoint'
  }

  const read = async notification => {
    notification.read = true
    client(`/notifications/read/${notification.id}`, { method: 'PUT' })
  }

  const getCommentTarget = comment => (comment.application ? getFullName(comment.application) : `l'offre ${comment.job.title}`)
  const getCommentUrl = comment =>
    comment.application ? `/ats/candidats/${comment.application.id}#commentaires` : `/ats/offres/${comment.job.id}#commentaires`
  const getNotificationUrl = notification => {
    if (notification.comment) return getCommentUrl(comment)
    else if (notification.application) return `/ats/candidats/${application.id}`
    else if (notification.job) return `/ats/offres/${job.id}`
  }
</script>

<template>
  <NuxtLink
    class="flex flex-col space-y-1 bg-white px-4 py-2 hover:bg-zinc-100"
    :to="getNotificationUrl(notification)"
    @mouseup="() => read(notification)"
  >
    <p class="text-xs text-zinc-300">{{ $dayjs(notification.createdAt).format('DD.MM.YY') }}</p>
    <div class="flex items-center space-x-2 text-wrap">
      <span class="h-2 w-2 min-w-2 rounded-full border border-viking-400 bg-white" v-if="notification.read" />
      <span class="h-2 w-2 rounded-full bg-viking-400" v-else />
      <p>{{ title[notification.type] }}</p>
    </div>
    <div class="flex flex-col text-wrap font-main text-sm font-normal">
      <div v-if="notification.type === 'commentMention'">
        <p>{{ comment.author.firstName }} {{ comment.author.lastName }} vous a identifié dans un commentaire sur {{ getCommentTarget(comment) }}:</p>
        <span class="mt-0.5 inline-block rounded-md border p-0.5">{{ comment.text.substring(0, 50) }}</span>
      </div>
      <div v-else-if="notification.type === 'comment'">
        <p>{{ comment.author.firstName }} {{ comment.author.lastName }} vient d’ajouter un commentaire sur {{ getCommentTarget(comment) }}:</p>
        <span class="mt-0.5 inline-block rounded-md border p-0.5">{{ comment.text.substring(0, 50) }}</span>
      </div>
      <div v-else-if="notification.application">
        <div class="flex flex-row items-center space-x-2">
          <Avatar
            class="h-8 w-8 shrink-0"
            :user="application.candidate?.user ?? { firstName: application.firstName, lastName: application.lastName }"
            :class="{
              'opacity-50': application.refused
            }"
          />
          <p v-if="notification.type === 'application'">
            {{ application.firstName }} {{ application.lastName }} vient de postuler à l'offre {{ application.job.title }}.
          </p>
          <p v-else-if="notification.type === 'spontaneousApplication'">
            {{ application.firstName }} {{ application.lastName }} vient de postuler spontanément à votre entreprise.
          </p>
          <p v-else-if="notification.type === 'recruitment'">
            {{ application.firstName }} {{ application.lastName }} vient de rejoindre vos équipes.
          </p>
        </div>
      </div>
      <div v-else-if="notification.type === 'endDateClose'">
        <p>L'offre {{ job.title }} se clôture le {{ job.expiresAt }}.</p>
      </div>
      <div v-else-if="notification.type === 'endDateOver'">
        <p>Votre offre {{ job.title }} est maintenant clôturée.</p>
      </div>
      <div v-else-if="notification.type === 'endDateOverUrgent'">
        <p>Plusieurs candidats attendent votre retour pour l’offre {{ job.title }}.</p>
      </div>
      <div v-else-if="notification.type === 'jobCreated'">
        <p>L'offre {{ job.title }} a été créée.</p>
      </div>
      <div v-else-if="notification.type === 'recruiterAdded'">
        <p>Vous avez été ajouté à l’offre {{ job.title }}.</p>
      </div>
    </div>
  </NuxtLink>
</template>
