<script setup>
  import { Dialog, DialogDescription, DialogPanel, DialogTitle } from '@headlessui/vue'
  import * as yup from 'yup'
  import { Field, Form } from 'vee-validate'
  import { notify } from 'notiwind'
  import { handleError } from '../utils/error'
  import signupIdentitySchema from '~/utils/schema/signup-identity-schema'
  import cguField from '~/utils/schema/cgu-field'

  const ats = useAts()
  const route = useRoute()
  const auth = useStrapiAuth()
  const client = useStrapiClient()
  const { fetchUser, user } = useUser()
  const top = useTop()

  const passwordYup = yup
    .string()
    .required('${label} requis')
    .min(12, 'Le mot de passe doit contenir au moins ${min} caractères')
    .matches(/[a-z]/, 'Le mot de passe doit contenir au moins une lettre minuscule')
    .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une lettre majuscule')
    .matches(/\d/, 'Le mot de passe doit contenir au moins un chiffre')
    .matches(/[!"#$%&'()*+,-./:;<=>?@[\]_`{}~]/, 'Le mot de passe doit contenir au moins un caractère spécial')
    .label('Mot de passe')

  const modals = {
    '#confirmation': true,
    '#connexion': {
      onSubmit: async (values, actions) => {
        if (modals['#connexion'].submitting.value) return
        modals['#connexion'].submitting.value = true
        try {
          const res = await auth.login(values)
          actions.resetForm()
          const redirect = useCookie('redirect', { path: '/' })
          if (redirect.value) {
            const path = redirect.value
            redirect.value = undefined
            await navigateTo(path, { replace: true })
          } else {
            await navigateTo(res.user.value?.employments ? '/ats' : '/', { replace: true })
          }
          notify({ group: 'main', type: 'success', title: 'Connexion réussie', text: `Bienvenue ${user.value.firstName}` })
        } catch (error) {
          error = error.error || error
          const errors = {
            'Invalid identifier or password': 'Email ou mot de passe incorrect',
            'Your account email is not confirmed': 'Email non confirmé',
            'Your account has been blocked by an administrator': 'Compte bloqué par un administrateur'
          }
          handleError(error, errors[error.message] || true)
          if (error.message === 'Your account email is not confirmed') {
            try {
              await auth.sendEmailConfirmation({ email: values.identifier })
              notify({
                group: 'main',
                type: 'info',
                title: 'Email envoyé',
                text: `Un email de confirmation a été envoyé à l’adresse ${values.identifier}`
              })
            } catch (error) {
              handleError(error, true)
            }
          }
        }
        modals['#connexion'].submitting.value = false
      },
      schema: yup.object({
        identifier: yup.string().required('${label} requis').email('${label} invalide').label('Email'),
        password: yup.string().required('${label} requis').label('Mot de passe')
      }),
      submitting: ref(false)
    },
    '#inscription': {
      onSubmit: async (values, actions) => {
        if (modals['#inscription'].submitting.value) return
        modals['#inscription'].submitting.value = true
        try {
          await auth.register({
            ...values,
            newsletter: !!values.newsletter,
            username: values.email,
            networks: {
              linkedin: values.linkedin
            }
          })
          actions.resetForm()
          await navigateTo('#connexion', { replace: true })
          notify(
            {
              group: 'main',
              type: 'success',
              title: 'Inscription réussie',
              text: `Pour confirmer votre inscription, cliquez sur le lien qui vous a été envoyé par mail à l’adresse <b>${values.email}</b> (pensez à vérifier aussi vos spams)`
            },
            5000
          )
        } catch (error) {
          error = error.error || error
          if (error.message === 'Email or Username are already taken') actions.setFieldError('email', 'Email déjà utilisé')
          else handleError(error, true)
        }
        modals['#inscription'].submitting.value = false
      },
      schema: yup.object({
        newsletter: yup.boolean(),
        password: passwordYup,
        cgu: cguField()
      }).concat(signupIdentitySchema()),
      submitting: ref(false)
    },
    '#invitation': {
      onSubmit: async (values, actions) => {
        if (modals['#invitation'].submitting.value) return
        modals['#invitation'].submitting.value = true
        try {
          const { email, token } = route.query
          await auth.register({ ...values, email, newsletter: !!values.newsletter, token, username: email })
          actions.resetForm()
          await navigateTo('#connexion', { replace: true })
          notify({ group: 'main', type: 'success', title: 'Merci pour votre inscription', text: 'Vous pouvez désormais vous connecter' })
        } catch (error) {
          error = error.error || error
          if (error.message === 'Email or Username are already taken') actions.setFieldError('email', 'Email déjà utilisé')
          else handleError(error, true)
        }
        modals['#invitation'].submitting.value = false
      },
      schema: yup.object({
        firstName: yup.string().required('${label} requis').label('Prénom'),
        lastName: yup.string().required('${label} requis').label('Nom'),
        newsletter: yup.boolean(),
        password: passwordYup
      }),
      submitting: ref(false)
    },
    '#motDePasseOublie': {
      onSubmit: async (values, actions) => {
        if (modals['#motDePasseOublie'].submitting.value) return
        modals['#motDePasseOublie'].submitting.value = true
        try {
          await auth.forgotPassword(values)
          actions.resetForm()
          await navigateTo({}, { replace: true })
          notify({
            group: 'main',
            type: 'success',
            title: 'Email envoyé',
            text: `Un email contenant un lien de réinitialisation a été envoyé à l’adresse <b>${values.email}</b>`
          })
        } catch (error) {
          handleError(error, true)
        }
        modals['#motDePasseOublie'].submitting.value = false
      },
      schema: yup.object({
        email: yup.string().required('${label} requis').email('${label} invalide').label('Email')
      }),
      submitting: ref(false)
    },
    '#reinitialisation': {
      onSubmit: async (values, actions) => {
        if (modals['#reinitialisation'].submitting.value) return
        modals['#reinitialisation'].submitting.value = true
        try {
          const { code } = route.query
          const res = await auth.resetPassword({ ...values, code })
          actions.resetForm()
          await navigateTo(res.user.value?.employments ? '/ats' : '/', { replace: true })
          notify({ group: 'main', type: 'success', title: 'Mot de passe réinitialisé' })
        } catch (error) {
          error = error.error || error
          const errors = {
            'code is a required field': 'Code est requis',
            'Invalid identifier or password': 'Email ou mot de passe incorrect',
            'Passwords do not match': 'Les mots de passe doivent être identiques'
          }
          handleError(error, errors[error.message] || true)
        }
        modals['#reinitialisation'].submitting.value = false
      },
      schema: yup.object({
        password: passwordYup,
        passwordConfirmation: passwordYup
          .oneOf([yup.ref('password')], 'Les mots de passe doivent être identiques')
          .label('Confirmation')
      }),
      submitting: ref(false)
    }
  }

  const modal = ref(!!modals[route.hash] ? route.hash : null)

  watch(
    () => route.hash,
    value => {
      modal.value = !!modals[value] ? value : null
    }
  )

  async function onClose() {
    await navigateTo({}, { replace: true })
  }

  onMounted(async () => {
    if (modal.value === '#confirmation') {
      try {
        const { code } = route.query
        if (!code) return await navigateTo({}, { replace: true })
        await client(`/auth/email-confirmation?confirmation=${code}`)
        if (user.value) {
          await fetchUser()
          await navigateTo('/mon-compte', { replace: true })
          notify({ group: 'main', type: 'success', title: 'Email confirmé' })
        } else {
          await navigateTo('#connexion', { replace: true })
          notify({ group: 'main', type: 'success', title: 'Email confirmé', text: 'Vous pouvez désormais vous connecter' })
        }
      } catch (error) {
        error = error.error || error
        const errors = {
          'confirmation is a required field': 'Code de confirmation requis',
          'Invalid token': 'Code de confirmation invalide'
        }
        await navigateTo({}, { replace: true })
        handleError(error, errors[error.message] || true)
      }
    } else if (modal.value === '#invitation') {
      try {
        const { id, token } = route.query
        if (!id) return
        await client(`/employments/confirm?token=${token}`, { method: 'POST' })
        if (user.value) {
          await fetchUser()
          await navigateTo('/ats', { replace: true })
          notify({ group: 'main', type: 'success', title: 'Invitation acceptée' })
        } else {
          await navigateTo('#connexion', { replace: true })
          notify({ group: 'main', type: 'success', title: 'Invitation acceptée', text: 'Vous pouvez désormais vous connecter' })
        }
      } catch (error) {
        await navigateTo({}, { replace: true })
        handleError(error, true)
      }
    }
  })

  useHead({
    bodyAttrs: {
      class: 'font-main text-base text-tarawera-900'
    },
    htmlAttrs: {
      class: 'md:text-[18px]'
    }
  })
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <LayoutsDefaultHeader />
    <main :class="['mt-16 duration-500', top ? 'md:mt-20' : 'md:mt-16']">
      <NuxtPage v-if="!$slots.default" />
      <slot />
    </main>
    <Footer class="mt-auto" v-if="!ats.isAts" />
    <Notifications />
  </div>

  <ClientOnly>
    <Dialog
      class="fixed inset-0 z-20 flex h-full w-full justify-center overflow-auto bg-black bg-opacity-50 p-4 py-24 font-main text-tarawera-900 backdrop-blur"
      :open="!!modal"
      @close="onClose"
    >
      <DialogPanel class="my-auto w-full max-w-sm rounded-xl bg-white p-4 shadow" id="confirmation" v-if="modal === '#confirmation'">
        <DialogTitle class="text-center font-special text-3xl font-bold">Confirmation</DialogTitle>
        <div class="my-8">
          <Loading />
        </div>
      </DialogPanel>

      <DialogPanel class="my-auto w-full max-w-sm rounded-xl bg-white p-4 shadow" id="connexion" v-else-if="modal === '#connexion'">
        <DialogTitle class="text-center font-special text-3xl font-bold">Connexion</DialogTitle>
        <Form class="mt-4" v-slot="{ errors }" :validationSchema="modals['#connexion'].schema" @submit="modals['#connexion'].onSubmit">
          <div class="-m-2 flex flex-wrap">
            <FormField
              class="basis-full p-2"
              autocomplete="email"
              :error="errors.identifier"
              name="identifier"
              placeholder="Email"
              required
              type="email"
            />
            <FormField
              class="basis-full p-2"
              autocomplete="current-password"
              :error="errors.password"
              name="password"
              placeholder="Mot de passe"
              required
              type="password"
            />
          </div>
          <div class="mt-2 text-right">
            <NuxtLink class="text-zinc-500 hover:text-viking-500" replace to="#motDePasseOublie">Mot de passe oublié&nbsp;?</NuxtLink>
          </div>
          <div class="mt-4 text-center">
            <Button :disabled="modals['#connexion'].submitting.value" type="submit">Se connecter</Button>
          </div>
        </Form>
        <div class="mt-4 text-center">
          Pas encore de compte&nbsp;?
          <NuxtLink class="text-viking-400 underline duration-300 hover:text-viking-500" replace to="#inscription">S’inscrire</NuxtLink>
        </div>
      </DialogPanel>

      <DialogPanel class="my-auto w-full max-w-lg rounded-xl bg-white p-4 shadow" id="inscription" v-else-if="modal === '#inscription'">
        <DialogTitle class="text-center font-special text-3xl font-bold">Je crée mon compte</DialogTitle>
        <Form class="mt-4" v-slot="{ errors, values }" :validationSchema="modals['#inscription'].schema" @submit="modals['#inscription'].onSubmit">
          <div class="-m-2 flex flex-wrap">
            <FormSignupIdentityFields :errors="errors" :values="values" />
            <FormField
              class="basis-full p-2"
              autocomplete="password"
              :error="errors.password"
              name="password"
              placeholder="Mot de passe"
              required
              type="password"
            />
            <span class="flex space-x-4 px-4 pb-2 text-sm text-zinc-500">
              <ul>
                <li>- 12 caractère minimum</li>
                <li>- 1 majuscule</li>
              </ul>
              <ul>
                <li>- 1 chiffre</li>
                <li>- 1 caractère spécial</li>
              </ul>
            </span>
            <FormField
              class="basis-full p-2 pt-4 text-sm"
              id="newsletter"
              label="J’accepte d’être contacté(e) par Profil Public (offre d’emploi, newsletter…)"
              name="newsletter"
              type="checkbox"
              :uncheckedValue="false"
              :value="true"
            />
            <div class="flex items-center p-2 space-x-2 text-sm">
              <FormCguCheckbox :error="errors.cgu" />
            </div>
            
          </div>
          <div class="grid grid-rows-2 sm:grid-rows-1 sm:grid-cols-3 mt-4 text-center">
            <Button class="sm:col-start-2" :disabled="modals['#inscription'].submitting.value" type="submit">S’inscrire</Button>
            <NuxtLink class="p-2 sm:ml-auto underline text-viking-400 duration-300 hover:text-viking-500 cursor-pointer" replace to="#connexion">J'ai déjà un compte</NuxtLink>
          </div>
        </Form>
      </DialogPanel>

      <DialogPanel class="my-auto w-full max-w-sm rounded-xl bg-white p-4 shadow" id="invitation" v-else-if="modal === '#invitation'">
        <template v-if="route.query.id">
          <DialogTitle class="text-center font-special text-3xl font-bold">Confirmation</DialogTitle>
          <div class="my-8">
            <Loading />
          </div>
        </template>
        <template v-else>
          <DialogTitle class="text-center font-special text-3xl font-bold">Inscription</DialogTitle>
          <DialogDescription class="mt-4 text-center">
            Créez votre compte. Une question&nbsp;?<br />
            <NuxtLink class="text-viking-400 hover:text-viking-500" to="/contact">Contactez-nous</NuxtLink>
          </DialogDescription>
          <Form class="mt-4" v-slot="{ errors }" :validationSchema="modals[modal].schema" @submit="modals[modal].onSubmit">
            <div class="-m-2 flex flex-wrap">
              <FormField class="basis-full p-2 sm:basis-1/2" :error="errors.firstName" name="firstName" placeholder="Prénom" required />
              <FormField class="basis-full p-2 sm:basis-1/2" :error="errors.lastName" name="lastName" placeholder="Nom" required />
              <div class="basis-full p-2">
                <input class="form-input w-full" placeholder="Email" readonly required type="email" :value="route.query.email" />
              </div>
              <FormField class="basis-full p-2" :error="errors.password" name="password" placeholder="Mot de passe" required type="password" />
            </div>
            <div class="mt-4">
              <label>
                <Field class="form-checkbox rounded text-viking-400" name="newsletter" type="checkbox" :unchecked-value="false" :value="true" />
                J’accepte d’être contacté(e) par Profil Public (offre d’emploi, newsletter…)
              </label>
            </div>
            <div class="mt-4 text-viking-400">
              En m’inscrivant, je certifie avoir lu et accepté les
              <NuxtLink class="underline duration-300 hover:text-viking-500" to="/conditions-generales">conditions générales d’utilisation</NuxtLink>
              de Profil Public.
            </div>
            <div class="mt-4 text-center">
              <Button :disabled="modals['#invitation'].submitting.value" type="submit">S’inscrire</Button>
            </div>
          </Form>
          <div class="mt-4 text-center">
            <NuxtLink class="text-zinc-500 duration-300 hover:text-viking-500" replace to="#connexion">Connexion</NuxtLink>
          </div>
        </template>
      </DialogPanel>

      <DialogPanel class="my-auto w-full max-w-sm rounded-xl bg-white p-4 shadow" id="motDePasseOublie" v-else-if="modal === '#motDePasseOublie'">
        <DialogTitle class="text-center font-special text-3xl font-bold">Mot de passe oublié</DialogTitle>
        <Form class="mt-4" v-slot="{ errors }" :validationSchema="modals[modal].schema" @submit="modals[modal].onSubmit">
          <FormField :error="errors.email" name="email" placeholder="Email" required type="email" />
          <div class="mt-4 text-center">
            <Button :disabled="modals['#motDePasseOublie'].submitting.value" type="submit">Valider</Button>
          </div>
        </Form>
        <div class="mt-4 text-center">
          <NuxtLink class="text-zinc-500 duration-300 hover:text-viking-500" to="#connexion">Connexion</NuxtLink>
        </div>
      </DialogPanel>

      <DialogPanel class="my-auto w-full max-w-sm rounded-xl bg-white p-4 shadow" id="reinitialisation" v-else-if="modal === '#reinitialisation'">
        <DialogTitle class="text-center font-special text-3xl font-bold">Réinitialisation</DialogTitle>
        <Form class="mt-4" v-slot="{ errors }" :validationSchema="modals[modal].schema" @submit="modals[modal].onSubmit">
          <div class="-m-2 flex flex-wrap">
            <FormField class="basis-full p-2" :error="errors.password" name="password" placeholder="Nouveau mot de passe" required type="password" />
            <FormField
              class="basis-full p-2"
              :error="errors.passwordConfirmation"
              name="passwordConfirmation"
              placeholder="Confirmation"
              required
              type="password"
            />
          </div>
          <div class="mt-4 text-center">
            <Button :disabled="modals['#reinitialisation'].submitting.value" type="submit">Valider</Button>
          </div>
        </Form>
        <div class="mt-4 text-center">
          <NuxtLink class="text-zinc-500 duration-300 hover:text-viking-500" replace to="#connexion">Se connecter</NuxtLink>
        </div>
      </DialogPanel>
    </Dialog>
  </ClientOnly>
</template>
